import { Timestamp } from '@angular/fire/firestore';

export enum NET_DATE_FORMAT {
  TIME = 'HH:mm',
  WEEKDAY = 'eeeeee',
  WEEKDAY_LONG = 'eeee',
  DATE = 'dd. MMM yyyy',
  WEEKDAYDATE = 'eeeeee., dd. MMM yyyy',
  WEEKDAYDATE_LONG = 'eeeeee., dd. MMMM yyyy',
  DATE_TIME = 'dd. MMMM HH:mm',
  DATE_TIME_YEAR = 'dd. MMMM yyyy HH:mm',
}

export type NetTsFirebase = Timestamp;
